<template>
    <div
      :class="(
        ($vuetify.breakpoint.xs) ? '' : 
        ($vuetify.breakpoint.sm) ? '' :        
        'main'
        )"
      >
      <div
        :class="(
        ($vuetify.breakpoint.xs) ? 'bglayersmall' : 
        ($vuetify.breakpoint.sm) ? 'bglayersmall' :        
        'bglayer'
        )"
        >
        <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="6 hidden-sm-and-down"/>
          <v-col class="d-flex" xs="12" md="6">  
          <v-spacer />
            <v-card color=transparent elevation=0 align='center'>
            <v-card-text
            :class="(
              ($vuetify.breakpoint.xs) ? 'textxsmall' :
              ($vuetify.breakpoint.sm) ? 'textsmall' :
              ($vuetify.breakpoint.md) ? 'textmed' : 
              ($vuetify.breakpoint.lg) ? 'text' :
              'text'
              )"
            >
              Technology used effectively is what binds us to our success. It allows for freedom of imagination
              and enables us to quickly and comfortably pivot in times of uncertainty.
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>



<script>
  export default {
  }
</script>


<style scoped>

.main {
  background: url('../../../assets/images/home/netcomm2.jpeg') no-repeat center 30%;
  background-size: 100%;
  height: 300px;
  font-size: 25px;
}

.bglayer{
  background: linear-gradient(to left, #001830, #001830 50%, transparent, transparent 85%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bglayersmall{
  background: #001830;
  width: 100%;
  height: 100%;
}

.text {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: x-large;
  text-align: right;
  line-height: 120%;
  max-width: 70%;
  padding-top: 60px;
  padding-left: 0px;
}

.textmed {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: x-large;
  text-align: right;
  line-height: 120%;
  max-width: 70%;
}

.textsmall {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: xx-large;
  text-align: center;
  padding: 50px;
}

.textxsmall {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: large;
  text-align: center;
  padding: 20px;
}

</style>