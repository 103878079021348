export default {
    partner: 
    {
        id: 1,
        title: "Partnering with ApexOps",
        text: "Whether you're one of our customers, or you've engaged us to supplement your expertise in delivering excellence to your \
        customers, we take our responsibility to achieve outstanding results very seriously. \
        We understand that mutually beneficial success isn't just something to aspire to, it’s a precondition of any successful engagement."

    },
    work:
    {
        id: 2,
        title: "Working for ApexOps",
        text: "IT has always been a progressive industry and this is something we continue to help move forward \
        every day. ApexOps has never been and will never be, a 9 to 5, office based grind. Our \
        customers engage us to achieve outcomes, so why would we hire you to do anything else? \
        Our initial interviews are tough and deeply technical, but well worth it. \
        How we engage with people will always be aligned to how we can best support them. Want to work 3.5 days per week? \
        No problem. Prefer to work from home every day? We encourage it. Need a highly structured environment because \
        that's how you feel you work best? Well ok then. We're all different and that's completely ok. \
        There are definitely weeks every year where we need to surge in order to support our customers \
        -which is par for the course in technology projects- but we believe it should be by exception. \
        We expect you to fully support our customers, and we understand that's not something you can do \
        without having our full support behind you."
    },
    corpres:
    {
        id: 3,
        title: "Corporate and Social Responsibility",
        text: "ApexOps is an equal opportunity employer. More than that, we take corporate and social responsibility, \
        environmental sustainability, and individual care and wellbeing seriously. A number of our senior staff \
        dedicate time, effort and resources to charitable and non-profit causes every week and as an organisation, \
        we endeavour to leave positive footprints behind anything we undertake."
    },
  }
  