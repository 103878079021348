export default {
    capability: {
        name: 'Capability',
        path: 'capability/',
        content: 'What we bring to our customers and how we can help you navigate 2022.'
    },
    capabilitytitle: {
        name: 'Capability-title',
        path: 'capability/',
        content: 'Structure - Simplicity - Delivery'
    },
    industry: {
        name: 'Industry',
        path: 'industry/',
        content: 'Key industries, current and recent projects and specialist expertise.'
    },
    projects: {
        name: 'Projects',
        path: 'projects/',
        content: 'Our approach to transformation and where we can best fit in to help you be where you want to be.'
    },
    about: {
        name: 'About',
        path: 'about/',
        content: 'Key information - how to get in touch, corporate responsibility, working for ApexOps.'
    }
}