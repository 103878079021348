export default {
    migration: 
    {
        id: 1,
        imgsrc: "migration.png",
        title: "Cloud Migration",
        text: "From individual application migrations to migrating entire regions of datacentres, there’s not much we haven’t achieved. \
        The majority of our projects typically involve cloud migration in at least some capacity and the extensive and proven \
        frameworks, processes and tooling we use ensure predictable, cost effective and maintainable cloud migrations at any scale."
        
    },
    fullstack:
    {
        id: 2,
        imgsrc: "fullstack.png",
        title: "Full stack applications",
        text:"Cloud based application builds, migrations, and optimisation at scale can often seem daunting for the most \
        accomplished teams. \
        We’re able to bring extensive experience across a wide variety of industries and platforms to help our customers expedite \
        projects, reduce risk and support stakeholders through all program and project phases."
    },
    projects:
    {
        id: 3,
        imgsrc: "projects.png",
        title: "Project Leadership",
        text: "The journey from inception to the realisation of a vision, requires a team who understand that successful \
        IT projects require a lot more than just the delivery of application code. Our unwavering focus on customer engagement, \
        regular reporting and watertight governance has enabled us to continually achieve significant outcomes for customers over \
        a breadth of technology projects."
    },
    dayzero:
    {
        id: 4,
        imgsrc: "dayzero.png",
        title: "Day Zero Operations",
        text:"There are still a lot of lot of organisations running legacy datacentre environments in 2022, and understanding \
        where to start when assessing a move to cloud can be complex – there’s a lot of information available online and many \
        people who understand their specific space within cloud, but not a lot of technology teams who regularly move large organisations into \
        contemporary technology at scale. We dedicate a lot of time helping customers migrate, and know from experience how best to \
        achieve it in a way that suits the needs of the organisation. From engaging with executive teams to build a suitable strategy, \
        to helping finance teams with the often complex modelling needed to understand what you’re commercially committing to \
        – we understand what’s needed, because we’ve repeatedly lived the journey with our customers."
    },
    infrastructure:
    {
        id: 5,
        imgsrc: "infrastructure.png",
        title: "Infrastructure",
        text:"We regularly see organisations ‘lifting and shifting’ networks and infrastructure into cloud, only to find performance \
        degradation, increased costs and difficulty in maintaining their new environments. Migrating servers one-for-one may be an \
        easy path, but it negates many of the significant benefits and cost savings cloud offers when done properly. Whether you need a pulse \
        check on a recent projects or an enterprise scale reorganisation and alignment of technology assets, we have teams available \
        to help when you need it most. "
    },
    security:
    {
        id: 6,
        imgsrc: "security.png",
        title: "Security",
        text:"The worst time to recognise the importance of cybersecurity is during incident management, and unfortunately it \
        happens far too often. As cloud matures we’re seeing fantastic improvements being made, but a significant gap still exists \
        between regulatory compliance and functional technology security. Industry awareness is slowly changing to recognise the \
        shift from security at the edge to security from the centre, as well as understanding that in 2022 people can be as much of a \
        network boundary as your firewalls. One thing that hasn’t changed however, is that no amount compliance with regulatory \
        frameworks will help secure an environment without the technical expertise to support it. From securing Police and Defence \
        systems, to building networks and platforms for critical utilities organisations, we can help provide the comfort needed to \
        ensure your environment remains as secure as it needs to be."
    },
    devops:
    {
        id: 7,
        imgsrc: "devops.png",
        title: "DevOps",
        text:"TBC"
    },
    serverless:
    {
        id: 8,
        imgsrc: "serverless.png",
        title: "Serverless",
        text:"Understanding the benefits and power of serverless computing can be a difficult endeavour the first time, but there’s \
        good reason for its recent explosive growth. From helping organisations first adopt serverless computing a few \
        years ago, every one of our application builds and rebuilds in 2021 were ultimately deployed without a server in sight. \
        The significantly reduced costs and risk, enhanced modularity and simplicity, and ease of maintenance has helped our \
        customers to achieve exceptional levels of organisational and technical agility during the unprecedented disruption \
        of covid. "
    },
    mobility:
    {
        id: 9,
        imgsrc: "mobility.png",
        title: "Mobile Applications",
        text:"With so much of today's technology being consumed through phones and mobile devices, providing customers with a \
        secure and enjoyable mobile experience when and where they need can add a lot of value to your application portfolio. \
        It's important to ensure applications are well planned, designed and built correctly from the very beginning, both to generate \
        positive app store reviews and also remove the complexity which is often associated with applications maintenance when this \
        doesn't occur. \
        Whether you're embarking on your first application, upgrading legacy software or building bespoke solutions on custom \
        devices, we can provide fully tested, easily maintainable solutions to suit even the most complex use cases."
    },
}
