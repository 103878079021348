<template>

<v-container class=footer fluid>
  To request a call back please email <a href="mailto:info@apexops.com.au">info@apexops.com.au</a><br>
  Brisbane - Melbourne - Sydney<br>
  ©ApexOps Pty Ltd 2022<br>
  
</v-container>
</template>

<script>
export default {

}
</script>

<style>
.footer {
  background-color: var(--v-dark-base);
  font-size: 12px;
  color: var(--v-light-base);
  text-align: center;
}
</style>