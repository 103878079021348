<template>

  <nav>
    <v-app-bar
      app
      color=primary
      optional
    >
    
    <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-sm-and-up">
    </v-app-bar-nav-icon>

    <v-tabs
      active-class="header_tab_active"
      color="custom5"
      fixed-tabs
      show-arrows
    >
    
    <v-tab>
      <v-flex class="text-center" >
        <router-link to="/home">
          <v-img
            :src="require('@/assets/images/global/header/apexOpsLogo.svg')"
            contain
            max-height="30px"
          />
        </router-link>
      </v-flex>
    </v-tab>
      

      <v-tab v-for="link in headerLinks" :key="link.title" :to="link.link" class="hidden-xs-and-down">
          <h3>
          {{ link.title }}
          </h3>
        </v-tab>
        
          <v-img
            :src="
              isDefaultImage
                ? require('@/assets/images/global/header/cloud_light.png')
                : require('@/assets/images/global/header/cloud_dark.png')
            "
            class="ma-2"
            position="bottom right"          
            width="45"
            @click="toggleTheme"
          />
          
      </v-tabs>
    </v-app-bar>
        }

    <!-- Start of mobile side menu -->

    <v-navigation-drawer app v-model="drawer" 
    absolute
    temporary>

      <!-- Menu title -->

      <!-- <v-toolbar flat>

        <v-list>

          <v-list-tile>

            <v-list-tile-title class="title">Menu</v-list-tile-title>

          </v-list-tile>

        </v-list>

      </v-toolbar>

      <v-divider></v-divider> -->

      <!-- Menu Links -->
      <v-list-item>
      <v-list-item-content>
        
      <v-flex>
        <router-link to="/home">
          <v-img
            :src="require('@/assets/images/global/header/apexOpsLogo.svg')"
            contain
            max-height="30px"
          />
        </router-link>
      </v-flex>
      </v-list-item-content>

      <!-- <v-list-item-content>
        <v-list-item-title class="text-h6">
          <v-img
          :src="require('@/assets/images/global/header/apexOpsLogo.svg')"
          width=120
          contain
        />
        <v-icon>
          mdi-menu
        </v-icon>
        </v-list-item-title>
      </v-list-item-content> -->
    </v-list-item>
    <v-divider/>

      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/"
        >
          <v-list-item-icon>
            <v-icon> mdi-home </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        
        <v-list-item
          v-for="item in headerLinks"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <!-- End of mobile side menu -->

  </nav>

    <!-- End of Navigation -->
</template>

<script>
export default {
  data: () => ({
    headerLinks: [
      {
        title: "Capability",
        link: "/capability",
        icon: "mdi-arrow-decision-auto-outline"
      },
      {
        title: "Industry",
        link: "/industry",
        icon: "mdi-bank"
      },
      {
        title: "Project Management",
        link: "/projects",
        icon: "mdi-account-supervisor-outline"
      },
      {
        title: "About",
        link: "/about",
        icon: "mdi-chat-processing-outline"
      }
    ],
    drawer: false,
    isDefaultImage: true,
  }),

  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      return (this.isDefaultImage = !this.isDefaultImage);
    }
  },
  watch: {
    '$vuetify.breakpoint.smAndUp'() {
      if(this.$vuetify.breakpoint.xsAndUp) {
        this.drawer = false;
        }
      }
    }
}
</script> 

<style>

.header_tab_active {
  background-color: transparent;
  color: var(--v-custom5-base) !important;
  font-size: 15px !important;
}
</style>