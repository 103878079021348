<template>
    <div>
      <div>
        <v-container class=rowlight fluid>
          <v-row>

          <v-col class="hidden-xs-and-down" sm="8" align-self="center">
            <v-img 
            :src="require('@/assets/images/about/apexOpsLogo.svg')"
            contain
            height="80px"
            /> 
          </v-col>

          <v-col xs="12" sm="4">
              <v-card class="cardcontact">
                      <v-card-title class="titletext">
                        Contact Us
                        <br>
                      </v-card-title>
                      <v-card-text class="linktext">
                          To request a callback please email   <a href="mailto:info@apexops.com.au">info@apexops.com.au</a>
                          <br><br><br><br>
                          <font size="+1">
                          Brisbane - Melbourne - Sydney<br></font>
                          
                          <font size="-1">
                          ©ApexOps Pty Ltd 2022                     
                          </font>
                      </v-card-text>
              </v-card>
          </v-col>

        </v-row>
        </v-container>
      </div>

      <div>
            <v-img 
            :src="require('@/assets/images/about/skyline.jpeg')"
            position="center"
            width="100%"
            height="200px"
            />
      </div>

        <v-container class=rowdark fluid>
          <v-row>
          <v-col xs="12" sm="12" md="6">
              <v-card class="cardabout">

                      <v-card-title class="titletext">
                        {{ bodydata.partner.title }}
                      </v-card-title>
                      <v-card-text class="abouttext">
                        {{ bodydata.partner.text }}
                        <br>
                      </v-card-text>

                      <v-card-title class="titletext justify-end">
                        {{ bodydata.work.title }}
                      </v-card-title>
                      <v-card-text class="abouttext">
                        {{ bodydata.work.text }}
                        <br>
                      </v-card-text>
                      
                      <v-card-title class="titletext">
                        {{ bodydata.corpres.title }}
                      </v-card-title>
                      <v-card-text class="abouttext">
                        {{ bodydata.corpres.text }}
                        <br><br>
                        We dont't use recruiters.
                      </v-card-text>

              </v-card>
          </v-col>

          <v-col class="hidden-sm-and-down" sm="6" align-self="center">
              <v-img 
                :src="require('@/assets/images/about/cloudlinux-light.png')"
                position right
                min-height="580px"
                />
                
          </v-col>

        </v-row>
        </v-container>

    </div>
</template>


<script>
  import bodydata from '@/content/about/body.js'

  export default {
    name: 'About',
    data() {
      return {
        bodydata,
      }
    }
  }
</script>


<style scoped>

.rowlight {
  background-color: var(--v-light-base);
  font-size: 12px;
  color: var(--v-light-base);
  text-align: center;
}

.rowdark {
  background-color: var(--v-dark-base);
  font-size: 12px;
  color: var(--v-light-base);
  text-align: center;
}

.cardcontact {
  background: var(--v-dark-base);
  font-size: 12px;
  padding-top: 20px;
  height: 250px;
}

.cardabout {
  background: var(--v-dark-base);
  font-size: 12px;
  color: var(--v-light-base);
  padding-top: 20px;
  min-height: 600px;
}

.titletext {
  color: var(--v-light-base) !important;
  font-size: large;
  line-height: 10px;
}

.abouttext {
  color: var(--v-light-base) !important;
  text-align: justify;
  font-size: medium;
}

.linktext {
  color: var(--v-light-base) !important;
  text-align: left;
  font-size: medium;
}

</style>
