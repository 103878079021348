<template>
    <div class=main>
      <v-container fluid class="pa-0">
      <v-row fluid>
        <v-col>
          <v-card
            colour=var(--v-textteriary-base)
            elevation=0
            max-width="90%"
            align='right'
            >
          <v-card-text
          :class="(($vuetify.breakpoint.xs) ? 'textsmall' : 
          (($vuetify.breakpoint.md) ? 'textmed' : 
          'text'))"
          class="textcol"
          >
              Thank you to everyone who's supported us during the covid years. We hope above all
              that you stay safe, cherish the people who support you, and make what light you can in these difficult times.
          </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>



<script>
  export default {
  }
</script>


<style scoped>

.textcol {
  color: var(--v-textprimary-base) !important
}

.text {
  filter: brightness(150%);
  font-style: italic;
  font-size: large;
  text-align: right;
  line-height: 200%;
  padding: 2%;
  max-width: 80%;
}

.textmed {
  font-style: italic;
  font-size: large;
  text-align: center;
  line-height: 250%;
  padding: 2%;
  max-width: 70%;
}

.textsmall {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  line-height: 200%;
  padding: 20px;
  max-width: 90%;
}

</style>