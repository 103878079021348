/**
* All Vuetify theme colours defined here
*/
export default {
    theme: {
        options: {
          customProperties: true,
        },
        themes: {
            light: {
                primary: '#fff',
                secondary: '#3b4b5f',
                
                light: '#fff',
                mid: '#e1efff',
                dark: '#3b4b5f',
                whitegrey: '#fff',
                whiteblack: '#fff',
                pm: '#fff',
                
                anchor: '#fff',

                custom1: '#fff',
                custom2: '#e1efff',
                custom3: '#b1cef0',
                custom4: '#81a0c4',
                custom5: '#526a85',
                custom6: '#3b4b5f', 

                bglight: '#fff',
                bgdark: '#3b4b5f',

                textprimary: '#3b4b5f',
                textsecondary: '#fff',
                textteriary: '#000',

                customx: '#ec222a',
            },
            dark: {
                primary: '#3b4b5f',
                secondary: '#fff',
                
                light: '#fff',
                mid: '#81a0c4',
                dark: '#3b4b5f',
                whitegrey: '#001e37',
                whiteblack: '#000',
                pm: '#7a878f',

                anchor: '#fff',

                custom1: '#3b4b5f',
                custom2: '#526a85',
                custom3: '#81a0c4',
                custom4: '#b1cef0',
                custom5: '#e1efff',
                custom6: '#fff',

                bglight: '#3b4b5f',
                bgdark: '#3b4b5f',

                textprimary: '#fff',
                textsecondary: '#3b4b5f',
                textteriary: '#fff',

                customx: '#ec222a',

            },
        }
    }
}