<template>
    <div
      :class="(
        ($vuetify.breakpoint.xs) ? '' : 
        ($vuetify.breakpoint.sm) ? '' :        
        'main'
        )"
      >
      <div
        :class="(
        ($vuetify.breakpoint.xs) ? 'bglayersmall' : 
        ($vuetify.breakpoint.sm) ? 'bglayersmall' :        
        'bglayer'
        )"
        >
        <v-container fluid class="pa-0">
        <v-row> 
          <v-col xs="12" md="6">
            <v-card color=transparent elevation=0 min-width="100%" height="200px" align='center'>
            <v-card-text
            :class="(
              ($vuetify.breakpoint.xs) ? 'textxsmall' :
              ($vuetify.breakpoint.sm) ? 'textsmall' :
              ($vuetify.breakpoint.md) ? 'textmed' : 
              ($vuetify.breakpoint.lg) ? 'text' :
              'text'
              )"
            >
                <font size="+2"> Driving everything we do is more than just <b>extensive capability</b> and innovative delivery. 
                It's an absolute focus on engagement and <b>customer experience</b>.</font>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>



<script>
  export default {
  }
</script>


<style scoped>

.main {
  background: url('../../../assets/images/home/midblue-dark.jpeg') no-repeat center center;
  background-size: 100%;
  height: 400px;
  font-size: 25px;
}

.bglayer{
  background: linear-gradient(to right, #001830, #001830 40%, transparent, transparent 75%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bglayersmall{
  background: #001830;
  width: 100%;
  height: 100%;
}

.text {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: xx-large;
  text-align: left;
  line-height: 150%;
  max-width: 60%;
  padding-top: 70px;
  padding-left: 0px;
}

.textmed {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: x-large;
  text-align: left;
  line-height: 180%;
  max-width: 70%;
}

.textsmall {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: large;
  text-align: center;
  line-height: 120%;
  max-width: 70%;
  padding: 25px;
}

.textxsmall {
  color:  var(--v-light-base) !important;
  font-style: italic;
  font-size: xx-small;
  text-align: center;
  max-width: 780%;
  padding: 20px;
}

</style>