<template>
    <div class=rowdark>
          <v-row no-gutters>
            <v-col>
              <v-card color="transparent" outlined tile align='center'>
                <v-card-text class="cardtextdark">
                    {{ bannerdata[this.pagename].content }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
    </div>
</template>


<script>

import bannerdata from '@/content/global/BannerData.js'

export default {
    name: 'Banner',
    props: {
        pagename: String
    },
    data: () => ({
        bannerdata
    })
}

</script>



<style scoped>
.cardtextdark {
  color:  var(--v-light-base) !important;
  padding: 8px;
}


.rowdark {
  background-color: var(--v-bgdark-base);
}

</style>