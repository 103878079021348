<template>
<div class="contmain">
    <v-container
        :class="(
        ($vuetify.breakpoint.xs) ? 'containerxs' :  
        ($vuetify.breakpoint.sm) ? 'container' :       
        'container'
        )"
        >
            <v-row
                class="rowcol"
                no-gutters
                >

                <v-col class="hidden-sm-and-down" sm="2" align-self="center">
                  <v-img
                  :src="require('@/assets/images/capability/capability-icons/' + capabilities[capability].imgsrc)"
                  contain
                  position="center"
                  height="80px"
                  padding-top="20px"
                  />
                </v-col>

                <v-col xs="12">
                  <v-card color="var(--v-primary-base)" flat>
                    <v-card-title class="cardtitletext">
                      {{capabilities[capability].title}}
                    </v-card-title>
                    <v-card-text class="cardtext">
                      {{capabilities[capability].text}}
                    </v-card-text>
                  </v-card>
                </v-col>

            </v-row>
            
    </v-container>
</div>
</template>


<script>
  import capabilities from '@/content/capability/capabilities.js'

  export default {
    name: 'Capabilities',
    props: {
        pagename: String,
        capability: String
    },
    data() {
      return {
        capabilities,
      }
    }
  }
</script>


<style scoped>

.rowcol {
  background-color: white;
}

.container {
  background: var(--v-bgdark-base);
  width: 70%;
}

.containerxs {
  background: var(--v-bgdark-base);
  width: 100%;
}

.contmain {
  padding-top: 20px;
}

.cardtitletext {
  color:  var(--v-textprimary-base) !important;
}

.cardtext {
  color:  var(--v-textprimary-base) !important;
  text-align: justify;
}

</style>