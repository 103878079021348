
import Vue from 'vue'
import Router from 'vue-router'
import paths from './paths'
/* eslint-disable no-unused-vars */



function route (path, view, name, props, meta) {
  return {
    meta: meta,
    name: name || view,
    props: props || false,
    path,
    component: (resovle) => import(
      `@/views/${view}.vue`
    ).then(resovle)
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name, path.props, path.meta)).concat([
    { path: "/", redirect: "/home"},
    { path: '*', redirect: "/404" },
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})


export default router
