/**
* All application routes defined here
*/
export default [
    {
        path: '/home',
        view: 'Home',  //Same as Views filename, relative to src/views/
        name: 'Home',
        meta: {
        title: "ApexOps | Home"
        }
    },
    {
        path: '/capability',
        view: 'Capability',  //Same as Views filename, relative to src/views/
        name: 'Capability',
        meta: {
        title: "ApexOps | Capability"
        }
    },
    {
        path: '/industry',
        view: 'Industry', //Same as Views filename, relative to src/views/
        name: 'Industry',
        meta: {
        title: "ApexOps | Industry"
        }
    },
    {
        path: '/projects',
        view: 'Projects', //Same as Views filename, relative to src/views/
        name: 'Projects',
        meta: {
        title: "ApexOps | Projects"
        }
    },
    {
        path: '/about',
        view: 'About', //Same as Views filename, relative to src/views/
        name: 'About',
        meta: {
        title: "ApexOps | About"
        }
    },
    {
        path: '/404',
        view: '404', //Same as Views filename, relative to src/views/
        name: '404',
        meta: {
        title: "ApexOps | Error"
        }
    }
]