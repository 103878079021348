import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import themecolours from './vuetifythemes';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {})

const opts = {}

export default new Vuetify(themecolours, opts);
