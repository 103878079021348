var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:(
      (_vm.$vuetify.breakpoint.xs) ? '' : 
      (_vm.$vuetify.breakpoint.sm) ? '' :        
      'main'
      )},[_c('div',{class:(
      (_vm.$vuetify.breakpoint.xs) ? 'bglayersmall' : 
      (_vm.$vuetify.breakpoint.sm) ? 'bglayersmall' :        
      'bglayer'
      )},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6 hidden-sm-and-down"}}),_c('v-col',{staticClass:"d-flex",attrs:{"xs":"12","md":"6"}},[_c('v-spacer'),_c('v-card',{attrs:{"color":"transparent","elevation":"0","align":"center"}},[_c('v-card-text',{class:(
            (_vm.$vuetify.breakpoint.xs) ? 'textxsmall' :
            (_vm.$vuetify.breakpoint.sm) ? 'textsmall' :
            (_vm.$vuetify.breakpoint.md) ? 'textmed' : 
            (_vm.$vuetify.breakpoint.lg) ? 'text' :
            'text'
            )},[_vm._v(" Technology used effectively is what binds us to our success. It allows for freedom of imagination and enables us to quickly and comfortably pivot in times of uncertainty. ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }