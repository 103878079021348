var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:(
      (_vm.$vuetify.breakpoint.xs) ? '' : 
      (_vm.$vuetify.breakpoint.sm) ? '' :        
      'main'
      )},[_c('div',{class:(
      (_vm.$vuetify.breakpoint.xs) ? 'bglayersmall' : 
      (_vm.$vuetify.breakpoint.sm) ? 'bglayersmall' :        
      'bglayer'
      )},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"6"}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0","min-width":"100%","height":"200px","align":"center"}},[_c('v-card-text',{class:(
            (_vm.$vuetify.breakpoint.xs) ? 'textxsmall' :
            (_vm.$vuetify.breakpoint.sm) ? 'textsmall' :
            (_vm.$vuetify.breakpoint.md) ? 'textmed' : 
            (_vm.$vuetify.breakpoint.lg) ? 'text' :
            'text'
            )},[_c('font',{attrs:{"size":"+2"}},[_vm._v(" Driving everything we do is more than just "),_c('b',[_vm._v("extensive capability")]),_vm._v(" and innovative delivery. It's an absolute focus on engagement and "),_c('b',[_vm._v("customer experience")]),_vm._v(".")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }