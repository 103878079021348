<template>

  <v-app>
    <!-- Header Component -->
    <global-header/> <!-- Links to src/components/global/Header.Vue -->


    <v-main>
      <!-- Display view pages here based on route -->
      <router-view></router-view>

    </v-main>

    <!-- Footer Component -->
    <global-footer/> <!-- Links to src/components/global/Footer.Vue -->

  </v-app>

</template>

 

<script>
  export default {
    name: "App",
    data() {
      return {
        drawer: false // Hide mobile side menu by default
      };
    }
  };

</script>

<style lang="scss">

@import '@/assets/scss/variables.scss';

.v-application {
  font-family: $body-font-family, !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: 300;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("/assets/fonts/nunito-v22-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Nunito"),
       url("/assets/fonts/nunito-v22-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("/assets/fonts/nunito-v22-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
       url("/assets/fonts/nunito-v22-latin-regular.woff") format("woff"), /* Modern Browsers */
       url("/assets/fonts/nunito-v22-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
       url("/assets/fonts/nunito-v22-latin-regular.svg#Nunito") format("svg"); /* Legacy iOS */
}

</style>