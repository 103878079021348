<template>
    <div class=rowdark>
          <v-row no-gutters justify="center">
              <v-col cols="6" class="headerspacing">
                  <v-card color="transparent" outlined tile align='center'>
                      <v-card-text class="cardtextdark">
                          STRUCTURE SIMPLICITY DELIVERY
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
    </div>
</template>


<script>
    export default {
        name: "Process",
        data() {
            return {
                titles: [
                    "STRUCTURE",
                    "SIMPLICITY",
                    "DELIVERY"
                ]
            }
        }
    }
</script>


<style scoped>
.cardtextdark {
  color:  var(--v-light-base) !important;
  opacity: .5;
  padding: 8px;
  word-spacing: 80px;
}

.rowdark {
  background-color: var(--v-bgdark-base);
  padding: 150 px;
}

</style>