<template>
<v-container>
  
      <v-row
        no-gutters
        >

        <v-col xs="12" md="8">
          <v-card color="var(--v-dark-base)" class="ma-2">
            <v-card-title class="text">
              {{industries[industry].title}}
            </v-card-title>
            <v-card-text class="text">
              {{industries[industry].text}}
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col class="hidden-xs-and-down" sm="4" align-self="center">
          <v-img
          class="img"
          :src="require('@/assets/images/industry/industry-images/' + industries[industry].imgsrc)" no-repeat
          position="center"
          height="250px"
          />
        </v-col>

      </v-row>
</v-container>
</template>


<script>
  import industries from '@/content/industry/industries.js'

  export default {
    name: 'Industries',
    props: {
        industry: String
    },
    data() {
      return {
        industries,
      }
    }
  }
</script>


<style scoped>

.img {
  margin-right: 10px;
  margin-left: 10px;
}

.text {
  color:  var(--v-light-base) !important;
  text-align: justify;
}

</style>