<template>
      <v-row
        align="center"
        >
        
        <v-col class="hidden-sm-and-down" md="2">
          <v-card class="imgbg">
            <v-img
            class="img"
            :src="require('@/assets/images/projects/projects-images/' + projects[project].imgsrc)"
            contain
            max-width="150px"
            />
            </v-card>
        </v-col>

        <v-col xs="12" md="10">
          <v-card color="var(--v-bglight-base)" height="100%" elevation="0">
            <v-card-title class="titletext">
              {{projects[project].title}}
            </v-card-title>
            <v-card-text class="text">
              {{projects[project].text}}
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
</template>


<script>
  import projects from '@/content/projects/projects.js'

  export default {
    name: 'Projects',
    props: {
        project: String
    },
    data() {
      return {
        projects,
      }
    }
  }
</script>


<style scoped>

.imgbg {
  background: var(--v-light-base) !important;
}

.img {
  background: var(--v-light-base) !important;
}


.text {
  color:  var(--v-textprimary-base) !important;
  text-align: justify;
  position: right;
  margin-right: 50px; /* NEEDED for large screen alignment. So Azure logo doesn't break text margin/elevation */
}


.titletext {
  color:  var(--v-textprimary-base) !important;
  text-align: center !important; /*DOES NOT WORK */
  font-size: xx-large;
  text-align: justify;
}

</style>