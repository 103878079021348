<template>
        <v-row align="center">
            <v-card-text/>
            <v-card-text/>

            <v-col
                v-for="item in process"
                :key=item
                :item=item
                >
                            <v-img
                                xs="12" sm="12"
                                :src="require('@/assets/images/home/process-images/' + item + '.png')"
                                max-width="100px"
                                class="mx-auto"
                                >
                            </v-img>
                            <v-card-text class="cardtext"> {{item}} </v-card-text>
            </v-col>
        
            <v-card-text/>
            <v-card-text/>

        </v-row>
</template>


<script>
    export default {
        name: "Process",
        data() {
            return {
                process: [
                    "Strategy",
                    "Design",
                    "Build",
                    "Support",
                    "Optimise"
                ]
            }
        }
    }
</script>


<style scoped>

.imgcard {
    padding-top: 30px;
}

.cardtext {
    font-size: medium;
    text-align: center;
}

</style>