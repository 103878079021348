<template>
<div>
  <v-container class="bgmain">
    <v-row
      justify="center"
      align="top"
      >
      <v-col 
        v-for="id in row1sections"
        :key="id"
        cols="12" sm="4" xs="12"
        >
        <v-card color="var(--v-dark-base)" :to="'/' + bannerdata[id].path">
          <v-card-title class="text">
            {{ bannerdata[id].name }}
          </v-card-title>
          <v-card-text class="text">
            {{ bannerdata[id].content }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row
      justify="center"
      align="top"
      >
      <v-col 
        v-for="id in row2sections"
        :key="id"
        cols="12" sm="4" xs="12">
        <v-card color="var(--v-dark-base)" :to="'/' + bannerdata[id].path">
          <v-card-title class="text">
            {{ bannerdata[id].name }}
          </v-card-title>
          <v-card-text class="text">
            {{ bannerdata[id].content }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</div>
</template>

<script>

import bannerdata from '@/content/global/BannerData.js'

  export default {
    name: 'Overview',
    data: () => ({
      bannerdata,
      row1sections: [
        'capability',
        'industry',
      ],
      row2sections: [
        'projects',
        'about'
      ],
    }),
  }

</script>


<style scoped>

.bgmain {
  padding-top: 40px;
  padding-bottom: 60px;
  background-size: 100%;
}

.text {
  color: var(--v-light-base) !important;
  text-align: left;
}

</style>