<template>
<v-container>
  
      <v-row
        >
        

        <v-col xs="12">
          <v-card color="var(--v-dark-base)">
            <v-card-title class="titletext">
              {{industries['defence'].title}}
            </v-card-title>
            <v-card-text class="text">
              {{industries['defence'].text}}              
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
</v-container>
</template>


<script>
  import industries from '@/content/industry/industries.js'

  export default {
    name: 'Industries',
    data: () => ({
      industries,
    })
  }
</script>


<style scoped>

.titletext {
  color:  var(--v-light-base) !important;
  font-size: medium;
}

.text {
  color:  var(--v-light-base) !important;
  font-size: small;
  text-align: left;
}

</style>