<template>
      <v-container class="main" fluid

        :class="(($vuetify.breakpoint.xs) ? 'textsmall' : 'text')"
        >
        PME
      </v-container>
</template>

  

<script>
  export default {
  }
</script>


<style scoped>

.main {
  background-image: url('../../../assets/images/projects/desk.jpeg');
  background-size: 100%;
  height: 200px;
  width: 100%;
  
  color: #a8b4c0 !important;
  text-align: right;
  font-size: 40px;
  
}

.text {
  font-size: 50px;
  padding-top: 15px;
  padding-right: 60px;

}

.textsmall {
  font-size: 10px;

}

</style>